import React from "react"

import LayoutThankYouClearViews from "../components/ThankYouClearviews/layoutThankYouClearViews"
import SEO from "../components/seo"

const ThankYouClearViewsPage = () => (
  <LayoutThankYouClearViews className="thankYouLP">
    <SEO title="LifeSync ClearViews" />
  </LayoutThankYouClearViews>
)

export default ThankYouClearViewsPage
