import React from "react"
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Logo from '../../images/lifesync-logo-white.svg'
import Radiolucency from "../../images/radiolucency.jpg"
import { TelephoneFill } from 'react-bootstrap-icons';


const HeaderThankYouClearViews = ({ siteTitleContact }) => (
  <header className="headerThankYou">
    <Container>
      <Row className="boxedNav">
        <Col xs={6}>
          <Link to="/">
            <img src={Logo} alt="LifeSync" width="185" className="logo" title="LifeSync" loading="lazy" />
          </Link>
        </Col>
        <Col xs={6} className="text-right">
          <a href="tel:18003582468" className="topRightPhoneBoxed">
            <span className="mobile"><TelephoneFill size={24} /></span>
            <span className="desktop">CALL US AT 1-800-358-2468</span>
          </a>
        </Col>
      </Row>
    </Container>
    <Container>
        <Row className="justify-content-center">
            <Col xs={12} lg={10} xl={8}>
                <div className="thankYouWrapper">
                    <div className="topThanks">
                        <Row>
                            <Col xs={12}>  
                                <h1>Thank you for registering!</h1>
                                <p className="lead">Your radiolucent leadwire is on its way.</p>
                            </Col>
                        </Row>
                        <Row>                            
                            <Col xs={12} className="text-center">
                                <img src={Radiolucency} alt="Thank you for registering" className="thanksHero" />
                            </Col>
                        </Row>
                    </div>
                    <div className="bottomThanks">
                        <Row>
                            <Col xs={12} md={8}>
                                <p>While you wait, here are a few benefits you can expect from your LifeSync Radiolucent leadwires.</p>
                            </Col>
                            <Col xs={12} md={4}>
                                <a className="btn btn-primary" href="https://www.lifesync.com/pdf/LifeSync-Radiolucent-Leadwires-Brochure.pdf" target="_blank" rel="noopener noreferrer">Download Now</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
  </header>
)

export default HeaderThankYouClearViews
